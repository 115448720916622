import { MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/category.png';
import { useProgressiveImage } from '../../hooks/useProgressiveImage';
import { type Tag } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../utils/media';
import { TagUtils } from './utils';

export function TagIcon(props: {
  tag: Tag;
  className?: string;
  priority?: readonly MediaFormatVersion[];
}) {
  const { tag, className, priority } = props;
  const iconURL = MediaUtils.PickMediaUrl(fromMediaDTO(tag.icon?.media), {
    priority,
  });

  return (
    <img src={iconURL || placeholder} alt={tag.name} className={className} />
  );
}

export function TagBackground(props: { tag?: Tag | null; className?: string }) {
  const { tag, className } = props;

  const placeholder = TagUtils.PickBackgroundURL(tag, {
    priority: ImagePickPriorityLowToHigh,
  });
  const src = TagUtils.PickBackgroundURL(tag, {
    priority: [MediaFormatVersion.Raw],
  });
  const { image, isLoading } = useProgressiveImage({
    placeholder,
    src,
  });

  return (
    <img
      src={image}
      className={`${className} ${isLoading ? 'filter blur' : 'blur-0'}`}
      alt='category background'
    />
  );
}
